import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import CoreuiVue from '@coreui/vue-pro'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
import DocsExample from '@/components/DocsExample'

import { Auth } from '@/services/Auth'
import DropZone from 'dropzone-vue'

import 'dropzone-vue/dist/dropzone-vue.common.css'
import mitt from 'mitt'

import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import * as Sentry from '@sentry/vue'

const emitter = mitt()

const app = createApp(App)
app.config.globalProperties.emitter = emitter

const vuetify = createVuetify({
  components,
  directives,
})

Sentry.init({
  app,
  dsn: 'https://94580a0562feb1c9d7fc09c85b56c9ca@sentry.isedo.pl/12',
})

app.use(store)
app.use(router)
app.use(CoreuiVue)
app.use(DropZone)
app.use(vuetify)
app.provide('icons', icons)
app.component('CIcon', CIcon)
app.component('DocsExample', DocsExample)

let auth
;(async () => {
  auth = new Auth(store, router)

  await auth.init()
})()

export { auth }

app.mount('#app')
